@import 'init';

body {

    background-color: $fondo-gris-claro;
    font-family: 'RC TYPE';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #333;
    display: flex;
    flex-direction: column;
    max-height: 100vh;

} 

.hidden {
    display: none;
}

.inline {
    display: inline;
}

.errmsg {
    background-color: #ffffcc;
    color: firebrick;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    border: solid 1px firebrick;
    border-radius: 10px;

}

.successmsg {
    background-color: #ccffff;
    color: black;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    border: solid 1px black;
    border-radius: 10px;

}

input, select, textarea {

    font-family: 'RC TYPE';

}


a {

    color: $rojo;

}

.baja {

    color: #f00;
}

.strong {

    font-weight: 500;

}

.logo {

    display: flex;
    justify-content: center;
    margin-bottom: 30px;


    img {
        height: 40px;
        display: block;
    }

}

header {

    padding: 30px 20px 0;
    border-bottom: 1px solid #d7d7d7;
    background-color: #fff;

    

    nav {

        font-weight: 500;
        

        ul.main {

            display: flex;
            align-items: center;
            align-self: center;
            max-width: $max-width;
            justify-content: space-evenly;
            margin: 0 auto;
            position: relative;
        

            li.main {

                padding: 0 20px;
                vertical-align: middle;
                position: static;
                

                & > a {

                    color: $negro;
                    padding: 8px 0;
                    display: block;
                    border-bottom: solid 2px transparent;
                    text-transform: uppercase;

                    &:hover, &.active {

                        color: $rojo;
                        border-color: $rojo;

                    }   

                }

                &:hover {

                    .float_overlay {

                        height: auto;
                        padding: 0 20px 20px;
                        // height: 100vh;

                    }

                }

                .float_overlay {

                    position:absolute;
                    width: 100%;
                    left: 0;
                    top: 36px;
                    background-color: #00000050;
                    z-index: 1;
                    height: 0;
                    box-sizing: border-box;
                    overflow: hidden;

                    .float {

                        padding: 30px 100px;
                        background-color: #fff;

                    }

                    .twocols {

                        display: flex;

                        & > div {

                            width: 50%;

                        }

                    }

                    ul {

                        display: flex;
                        flex-direction: column;

                    }

                    h3 {

                        color: $rojo;
                        margin-bottom: 10px;
                        

                    }

                    a {

                        color: $negro-claro;
                        padding: 10px 0;
                        display: block;

                        &:hover {

                            color: $rojo;
                            text-decoration: underline;

                        }

                    }




                }

            }

        }

    }

}

.button {
    
    cursor: pointer;
    background: $rojo;
    border: 0;
    border-radius: 50px;
    width: auto;
    padding: 0 38px;
    font-family: "RC TYPE";
    font-size: 14px;
    height: 50px;
    color: #fff;

    &:disabled {

        opacity: .5;
        cursor: default;

    }
    
}

.button-outline {
    
    cursor: pointer;
    background: #e2001a !important;
    border: 0;
    border-radius: 15px;
    width: auto;
    padding: 0 38px;
    font-family: "RC TYPE";
    font-size: 14px;
    height: 30px;
    color: #fff;
    
}

.content {

    padding: 20px;
    overflow: auto;
    min-height: 100vh;

    h2.page_title {

        font-weight: 400;
        color: $rojo;            
        text-align: center;
        margin: 20px;
        font-size: 24px;
        line-height: 24px;

    }

    &.white {

        background-color: #fff;

    }

    .form_div {

        max-width: $max-width;
        margin: 0 auto;

    }


    .form {

        background: #fff;
        padding: 40px;

        h2.title {

            padding-top: 40px;
            padding-bottom: 60px;
            text-align: center;
            font-weight: 800;
            font-size: 18px;
            line-height: 36px;

        }

        .form_row {

            display: flex;
            column-gap: 40px;

            & > div.field {

                width: 100%;

            }
            
            &.two > div.field {

                width: calc(50% - 20px);

            }

        }



        &.login {

            max-width: 400px;
            margin: 0 auto;

        }

        .field {

            &.right {
                text-align: right;
            }

            margin-bottom: 30px;
    
            label {
    
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                color: #666;
            }
    
            input[type=text],input[type=email],input[type=number],input[type=password],select,textarea {
    
                font-size: 16px;
                border: 0 none;
                color: #666;
                font-weight: 300;
                width: 100%;
                padding: 12px 10px 10px 2px;
                outline: none;
                border-bottom: 2px solid #d7d7d7;

                &::placeholder {

                    color: #CCC;
                }
                
            }
            
            textarea{
                
                border: 2px solid #d7d7d7;
                margin-top: 4px;
                height: 150px;
                font-size: 12px;
                padding: 5px;
                font-family: Courier, "Lucida Console", monospace;
                color:#00000040;

            }

            input {

                padding-left: 7px;

            }

            input[type="checkbox"] {

                appearance: none;
                position: relative;
                height: 14px;
                width: 30px;
                background-color: #d7d7d7;
                border-radius: 5rem;
                transition: background-color .2s;
                box-shadow: 0 0 15px #0000001e;
                cursor: pointer;
                vertical-align: middle;


                &::after {

                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 20%;
                    transform: translate(-50%, -50%);
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: #AAA;
                    transition: left .2s;

                }

                &:checked {

                    background-color: #f0a5a5;

                    &::after {

                        left:80%;
                        background-color: $rojo;
                    }

                }
            }


        }

        .buttons {


            text-align: center;
            
            button {

                font-weight: 600;
                margin: 0 20px;

            }
            

        }

    }

    .light {

        opacity: 0.5;

    }

    .info_div {

        background-color: #fff;
        padding: 20px;
        margin-bottom: 20px;
        font-size: 14px;

        h2 {

            text-align: center;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 20px;

            

        }

        ul {

            display: flex;
            justify-content: space-evenly;

            li {

                text-align: center;

                span {

                    display: inline-block;
                    margin: 0 2px;

                    &.valor {
    
                        font-size: 22px;
                        line-height: 22px;
    
                    }

                }


            }

        }

    }

    .filters_div {

        font-size: 14px;
        padding-bottom: 20px; 

        ul {

            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;

            li {

                margin: 0 10px;

                label {

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {

                        margin-right: 7px;

                    }

                    input, select {

                        border: solid 1px #CCC;
                        padding: 5px 8px;
                        border-radius: 15px;
                        margin: 0;

                    }

                }

            }

        }

    }



    .no_results {

        text-align: center;
        padding: 50px;
        margin-top: 48px;
        font-weight: 500;
        background: #fff;

    }

    .table {

        font-size: 14px;
        margin-left: auto;
        margin-right: auto;

        thead {

            tr {

                td {

                    font-weight: 500;
                    padding: 5px 15px;
                    vertical-align: middle;

                   

                }

                

            }

        }

        &.pd10 tbody td {
            padding: 10px;
        }

        tbody {

            tr {

                &:hover {

                    background-color: #fafafa;

                }

                background-color: #fff;

                td {

                    padding: 15px;
                    vertical-align: middle;
                    line-height: 20px;
                    cursor: default;

                    .valor {

                        font-weight: 500;
                        font-size: 16px;

                    }

                    .perc {

                        font-size: 14px;

                    }

                    a.vinculo {

                        font-weight: 500;
                        color: $negro;
        
                    }

                }
                
                
                
            }

        }
        
        td.c {

            text-align: center;

        }

        td.r {

            text-align: right;

        }


        

    }

    .flex-horizontal {
        display: flex;
        flex-direction: column;
    }
    
}
