html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer, header, nav, section, main, .block{
    display: block;
}

body{
    line-height: 1;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input{
    -webkit-appearance: none;
    border-radius: 0;
}

a{

    text-decoration: none;

}

// FIN RESET //

@mixin mobile() {
    @media (max-width:768px){
        @content;
    }
}

*, *:before, *:after{
    box-sizing: border-box;
}



// COLORS
$fondo-gris-claro: #f6f6f6;
$rojo: #e42c31;
$negro: #333;
$negro-claro: #666;
$vinculo: #444444;


@for $i from 1 to 11 {
    .mb--#{$i*10} {
        margin-bottom:#{$i*10}px;
    }
    .mt--#{$i*10} {
        margin-top:#{$i*10}px;
    }
    .ml--#{$i*10} {
        margin-left:#{$i*10}px;
    }
    .mr--#{$i*10} {
        margin-right:#{$i*10}px;
    }
}

@for $i from 1 to 11 {
    .pb--#{$i*10} {
        padding-bottom:#{$i*10}px;
    }
    .pt--#{$i*10} {
        padding-top:#{$i*10}px;
    }
    .pl--#{$i*10} {
        padding-left:#{$i*10}px;
    }
    .pr--#{$i*10} {
        padding-right:#{$i*10}px;
    }
}

@for $i from 1 to 11 {
    .cg--#{$i*10} {
        column-gap:#{$i*10}px;
    }
}


// WIDTHS
$max-width: 1120px;

@for $i from 10 to 21 {
    .w--#{$i*10} {
        width:#{$i*10}px;
    }
}


.strong, .bold {

    font-weight: 500;

}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.center {
    text-align: center;
}

.flex {

    display: flex;

    &.row {
    
        flex-direction: row;
    
    }

    &.space-around {

        justify-content: space-around;

    }

    &.space-between {

        justify-content: space-between;

    }

}
