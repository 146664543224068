html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main, .block {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

a {
  text-decoration: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.mb--10 {
  margin-bottom: 10px;
}

.mt--10 {
  margin-top: 10px;
}

.ml--10 {
  margin-left: 10px;
}

.mr--10 {
  margin-right: 10px;
}

.mb--20 {
  margin-bottom: 20px;
}

.mt--20 {
  margin-top: 20px;
}

.ml--20 {
  margin-left: 20px;
}

.mr--20 {
  margin-right: 20px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mt--30 {
  margin-top: 30px;
}

.ml--30 {
  margin-left: 30px;
}

.mr--30 {
  margin-right: 30px;
}

.mb--40 {
  margin-bottom: 40px;
}

.mt--40 {
  margin-top: 40px;
}

.ml--40 {
  margin-left: 40px;
}

.mr--40 {
  margin-right: 40px;
}

.mb--50 {
  margin-bottom: 50px;
}

.mt--50 {
  margin-top: 50px;
}

.ml--50 {
  margin-left: 50px;
}

.mr--50 {
  margin-right: 50px;
}

.mb--60 {
  margin-bottom: 60px;
}

.mt--60 {
  margin-top: 60px;
}

.ml--60 {
  margin-left: 60px;
}

.mr--60 {
  margin-right: 60px;
}

.mb--70 {
  margin-bottom: 70px;
}

.mt--70 {
  margin-top: 70px;
}

.ml--70 {
  margin-left: 70px;
}

.mr--70 {
  margin-right: 70px;
}

.mb--80 {
  margin-bottom: 80px;
}

.mt--80 {
  margin-top: 80px;
}

.ml--80 {
  margin-left: 80px;
}

.mr--80 {
  margin-right: 80px;
}

.mb--90 {
  margin-bottom: 90px;
}

.mt--90 {
  margin-top: 90px;
}

.ml--90 {
  margin-left: 90px;
}

.mr--90 {
  margin-right: 90px;
}

.mb--100 {
  margin-bottom: 100px;
}

.mt--100 {
  margin-top: 100px;
}

.ml--100 {
  margin-left: 100px;
}

.mr--100 {
  margin-right: 100px;
}

.pb--10 {
  padding-bottom: 10px;
}

.pt--10 {
  padding-top: 10px;
}

.pl--10 {
  padding-left: 10px;
}

.pr--10 {
  padding-right: 10px;
}

.pb--20 {
  padding-bottom: 20px;
}

.pt--20 {
  padding-top: 20px;
}

.pl--20 {
  padding-left: 20px;
}

.pr--20 {
  padding-right: 20px;
}

.pb--30 {
  padding-bottom: 30px;
}

.pt--30 {
  padding-top: 30px;
}

.pl--30 {
  padding-left: 30px;
}

.pr--30 {
  padding-right: 30px;
}

.pb--40 {
  padding-bottom: 40px;
}

.pt--40 {
  padding-top: 40px;
}

.pl--40 {
  padding-left: 40px;
}

.pr--40 {
  padding-right: 40px;
}

.pb--50 {
  padding-bottom: 50px;
}

.pt--50 {
  padding-top: 50px;
}

.pl--50 {
  padding-left: 50px;
}

.pr--50 {
  padding-right: 50px;
}

.pb--60 {
  padding-bottom: 60px;
}

.pt--60 {
  padding-top: 60px;
}

.pl--60 {
  padding-left: 60px;
}

.pr--60 {
  padding-right: 60px;
}

.pb--70 {
  padding-bottom: 70px;
}

.pt--70 {
  padding-top: 70px;
}

.pl--70 {
  padding-left: 70px;
}

.pr--70 {
  padding-right: 70px;
}

.pb--80 {
  padding-bottom: 80px;
}

.pt--80 {
  padding-top: 80px;
}

.pl--80 {
  padding-left: 80px;
}

.pr--80 {
  padding-right: 80px;
}

.pb--90 {
  padding-bottom: 90px;
}

.pt--90 {
  padding-top: 90px;
}

.pl--90 {
  padding-left: 90px;
}

.pr--90 {
  padding-right: 90px;
}

.pb--100 {
  padding-bottom: 100px;
}

.pt--100 {
  padding-top: 100px;
}

.pl--100 {
  padding-left: 100px;
}

.pr--100 {
  padding-right: 100px;
}

.cg--10 {
  column-gap: 10px;
}

.cg--20 {
  column-gap: 20px;
}

.cg--30 {
  column-gap: 30px;
}

.cg--40 {
  column-gap: 40px;
}

.cg--50 {
  column-gap: 50px;
}

.cg--60 {
  column-gap: 60px;
}

.cg--70 {
  column-gap: 70px;
}

.cg--80 {
  column-gap: 80px;
}

.cg--90 {
  column-gap: 90px;
}

.cg--100 {
  column-gap: 100px;
}

.w--100 {
  width: 100px;
}

.w--110 {
  width: 110px;
}

.w--120 {
  width: 120px;
}

.w--130 {
  width: 130px;
}

.w--140 {
  width: 140px;
}

.w--150 {
  width: 150px;
}

.w--160 {
  width: 160px;
}

.w--170 {
  width: 170px;
}

.w--180 {
  width: 180px;
}

.w--190 {
  width: 190px;
}

.w--200 {
  width: 200px;
}

.strong, .bold {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}
.flex.row {
  flex-direction: row;
}
.flex.space-around {
  justify-content: space-around;
}
.flex.space-between {
  justify-content: space-between;
}

body {
  background-color: #f6f6f6;
  font-family: "RC TYPE";
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #333;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

.errmsg {
  background-color: #ffffcc;
  color: firebrick;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  border: solid 1px firebrick;
  border-radius: 10px;
}

.successmsg {
  background-color: #ccffff;
  color: black;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  border: solid 1px black;
  border-radius: 10px;
}

input, select, textarea {
  font-family: "RC TYPE";
}

a {
  color: #e42c31;
}

.baja {
  color: #f00;
}

.strong {
  font-weight: 500;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.logo img {
  height: 40px;
  display: block;
}

header {
  padding: 30px 20px 0;
  border-bottom: 1px solid #d7d7d7;
  background-color: #fff;
}
header nav {
  font-weight: 500;
}
header nav ul.main {
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 1120px;
  justify-content: space-evenly;
  margin: 0 auto;
  position: relative;
}
header nav ul.main li.main {
  padding: 0 20px;
  vertical-align: middle;
  position: static;
}
header nav ul.main li.main > a {
  color: #333;
  padding: 8px 0;
  display: block;
  border-bottom: solid 2px transparent;
  text-transform: uppercase;
}
header nav ul.main li.main > a:hover, header nav ul.main li.main > a.active {
  color: #e42c31;
  border-color: #e42c31;
}
header nav ul.main li.main:hover .float_overlay {
  height: auto;
  padding: 0 20px 20px;
}
header nav ul.main li.main .float_overlay {
  position: absolute;
  width: 100%;
  left: 0;
  top: 36px;
  background-color: rgba(0, 0, 0, 0.3137254902);
  z-index: 1;
  height: 0;
  box-sizing: border-box;
  overflow: hidden;
}
header nav ul.main li.main .float_overlay .float {
  padding: 30px 100px;
  background-color: #fff;
}
header nav ul.main li.main .float_overlay .twocols {
  display: flex;
}
header nav ul.main li.main .float_overlay .twocols > div {
  width: 50%;
}
header nav ul.main li.main .float_overlay ul {
  display: flex;
  flex-direction: column;
}
header nav ul.main li.main .float_overlay h3 {
  color: #e42c31;
  margin-bottom: 10px;
}
header nav ul.main li.main .float_overlay a {
  color: #666;
  padding: 10px 0;
  display: block;
}
header nav ul.main li.main .float_overlay a:hover {
  color: #e42c31;
  text-decoration: underline;
}

.button {
  cursor: pointer;
  background: #e42c31;
  border: 0;
  border-radius: 50px;
  width: auto;
  padding: 0 38px;
  font-family: "RC TYPE";
  font-size: 14px;
  height: 50px;
  color: #fff;
}
.button:disabled {
  opacity: 0.5;
  cursor: default;
}

.button-outline {
  cursor: pointer;
  background: #e2001a !important;
  border: 0;
  border-radius: 15px;
  width: auto;
  padding: 0 38px;
  font-family: "RC TYPE";
  font-size: 14px;
  height: 30px;
  color: #fff;
}

.content {
  padding: 20px;
  overflow: auto;
  min-height: 100vh;
}
.content h2.page_title {
  font-weight: 400;
  color: #e42c31;
  text-align: center;
  margin: 20px;
  font-size: 24px;
  line-height: 24px;
}
.content.white {
  background-color: #fff;
}
.content .form_div {
  max-width: 1120px;
  margin: 0 auto;
}
.content .form {
  background: #fff;
  padding: 40px;
}
.content .form h2.title {
  padding-top: 40px;
  padding-bottom: 60px;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 36px;
}
.content .form .form_row {
  display: flex;
  column-gap: 40px;
}
.content .form .form_row > div.field {
  width: 100%;
}
.content .form .form_row.two > div.field {
  width: calc(50% - 20px);
}
.content .form.login {
  max-width: 400px;
  margin: 0 auto;
}
.content .form .field {
  margin-bottom: 30px;
}
.content .form .field.right {
  text-align: right;
}
.content .form .field label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #666;
}
.content .form .field input[type=text], .content .form .field input[type=email], .content .form .field input[type=number], .content .form .field input[type=password], .content .form .field select, .content .form .field textarea {
  font-size: 16px;
  border: 0 none;
  color: #666;
  font-weight: 300;
  width: 100%;
  padding: 12px 10px 10px 2px;
  outline: none;
  border-bottom: 2px solid #d7d7d7;
}
.content .form .field input[type=text]::placeholder, .content .form .field input[type=email]::placeholder, .content .form .field input[type=number]::placeholder, .content .form .field input[type=password]::placeholder, .content .form .field select::placeholder, .content .form .field textarea::placeholder {
  color: #CCC;
}
.content .form .field textarea {
  border: 2px solid #d7d7d7;
  margin-top: 4px;
  height: 150px;
  font-size: 12px;
  padding: 5px;
  font-family: Courier, "Lucida Console", monospace;
  color: rgba(0, 0, 0, 0.2509803922);
}
.content .form .field input {
  padding-left: 7px;
}
input[type=checkbox].switch {
  appearance: none;
  position: relative;
  height: 14px;
  width: 30px;
  background-color: #d7d7d7;
  border-radius: 5rem;
  transition: background-color 0.2s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1176470588);
  cursor: pointer;
  vertical-align: middle;
}
input[type=checkbox].switch.readonly {
  cursor: default;
}
input[type=checkbox].switch::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #AAA;
  transition: left 0.2s;
}
input[type=checkbox].switch:checked {
  background-color: #f0a5a5;
}
input[type=checkbox].switch:checked::after {
  left: 80%;
  background-color: #e42c31;
}
.content .form .buttons {
  text-align: center;
}
.content .form .buttons button {
  font-weight: 600;
  margin: 0 20px;
}
.content .light {
  opacity: 0.5;
}
.content .info_div {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}
.content .info_div h2 {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 20px;
}
.content .info_div ul {
  display: flex;
  justify-content: space-evenly;
}
.content .info_div ul li {
  text-align: center;
}
.content .info_div ul li span {
  display: inline-block;
  margin: 0 2px;
}
.content .info_div ul li span.valor {
  font-size: 22px;
  line-height: 22px;
}
.content .filters_div {
  font-size: 14px;
  padding-bottom: 20px;
}
.content .filters_div ul {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.content .filters_div ul li {
  margin: 0 10px;
}
.content .filters_div ul li label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content .filters_div ul li label span {
  margin-right: 7px;
}
.content .filters_div ul li label input, .content .filters_div ul li label select {
  border: solid 1px #CCC;
  padding: 5px 8px;
  border-radius: 15px;
  margin: 0;
}
.content .no_results {
  text-align: center;
  padding: 50px;
  margin-top: 48px;
  font-weight: 500;
  background: #fff;
}
.content .table {
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}
.content .table thead tr td {
  font-weight: 500;
  padding: 5px 15px;
  vertical-align: middle;
}
.content .table.pd10 tbody td {
  padding: 10px;
}
.content .table tbody tr {
  background-color: #fff;
}
.content .table tbody tr:hover {
  background-color: #fafafa;
}
.content .table tbody tr td {
  padding: 15px;
  vertical-align: middle;
  line-height: 20px;
  cursor: default;
}
.content .table tbody tr td .valor {
  font-weight: 500;
  font-size: 16px;
}
.content .table tbody tr td .perc {
  font-size: 14px;
}
.content .table tbody tr td a.vinculo {
  font-weight: 500;
  color: #333;
}
.content .table td.c {
  text-align: center;
}
.content .table td.r {
  text-align: right;
}
.content .flex-horizontal {
  display: flex;
  flex-direction: column;
}